<template>
  <y_layout>
    <YTabs  v-model="tabName">
      <YTabPane card label="系统模版" name="sys">
        <sys :active-id="parseInt($route.query.active_id) || 0" :mode="parseInt($route.params.mode)"></sys>
      </YTabPane>
      <YTabPane card label="我的模版" name="user">
        <user :active-id="parseInt($route.query.active_id) || 0" :mode="parseInt($route.params.mode)"></user>
      </YTabPane>
    </YTabs>
<!--    <el-tabs v-model="tabName" type="border-card" style="margin-top: 1rem">-->
<!--      <el-tab-pane label="系统模版" name="sys">-->
<!--        <sys :active-id="parseInt($route.query.active_id) || 0" :mode="parseInt($route.params.mode)"></sys>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="我的模版" name="user">-->
<!--        <user :active-id="parseInt($route.query.active_id) || 0" :mode="parseInt($route.params.mode)"></user>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import sys from "@/view/tpl/list/sys";
import user from "@/view/tpl/list/user";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "list",
  components:{
    YTabs,
    YTabPane,
    y_layout,
    user,
    sys
  },
  data(){
    return{
      tabName:"sys"
    }
  }
}
</script>

<style scoped>

</style>