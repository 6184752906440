<template>
  <div>
    <add ref="conBox" @close="active_key_lock=false" :tpl-create="true"></add>
    <el-row v-loading="loading" :gutter="20" style="min-height: 37rem">
      <el-col v-for="(item,key) in list" :key="key" :span="6">
        <div @mouseout="mouseOut" class="flex-def flex-zTopBottom"
             style="border-radius: 5px;border:1px solid #f8f8f8;overflow: hidden;;margin:1rem">
          <div style="position: relative">
            <el-image @mouseenter="mouseEnter(key)" :src="item.pic | tomedia"></el-image>
            <div :class="active_key === key ? '' : 'hidden'" class="flex-def flex-zCenter flex-cCenter"
                 @mouseenter="mouseEnter(key)"
                 style="background-color: rgba(0,0,0,.4);position: absolute;left: 0;right: 0;bottom: 0;top: 0">
              <el-button @click="choose" @mouseenter="mouseEnter(key)" type="primary" size="mini">选用模版</el-button>
            </div>
          </div>
          <div @mouseenter="mouseEnter(key)" class="text-ellipsis"
               style="padding: .5rem;font-size: 12px;font-weight: 600">{{ item.title }}
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="flex-def flex-zCenter flex-cCenter">
<!--      <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"-->
<!--                     :total="total" :page-size="12" :current-page="page" @current-change="pageChange">-->
<!--      </el-pagination>-->
    </div>
  </div>
</template>

<script>
import add from "@/view/active/add/index";
export default {
  name: "sys",
  components:{add},
  props:{
    mode:{
      type:Number,
      default:0
    },
    activeId:{
      type:Number,
      default: 0
    }
  },
  data(){
    return{
      active_key:false,
      list:[],
      page:1,
      total:0,
      loading:false,
      active_key_lock:false,
    }
  },
  mounted(){
    this.load();
  },
  methods:{
    choose(){
      this.active_key_lock = true;
      if(!this.$route.query.active_id){
        this.$refs.conBox.modeChoose(this.mode)
        this.$refs.conBox.createActive(active_id=>{
          this.$api.active.designFetch({active_id:active_id}).then(()=>{
            this.activeIdFullDesign(active_id);
          })
        });
        return;
      }
      this.activeIdFullDesign(this.$route.query.active_id);
    },
    activeIdFullDesign(active_id){
      let item = this.list[this.active_key];
      this.$api.active.designTplSysOne({id:item.id}).then((res)=>{
        this.$api.active.designUpdate({
          active_id:parseInt(active_id),
          content:res.content,
          bg_color:res.bg_color,
          bg_audio:res.bg_audio,
        }).then(()=>{
          this.$message.success("页面设计填充成功");
          this.$router.push({
            name:"activeEdit",
            params:this.$route.params,
            query:{
              id:active_id,
              tab:"design",
            }
          })
        })
      })
    },
    mouseEnter(key){
      if (this.active_key_lock)return;
      this.active_key = key;
    },
    mouseOut(){
      // this.active_key = false;
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      if(this.$route.query.active_id){
        this.$api.active.designFetch({active_id:parseInt(this.$route.query.active_id)}).then()
      }
      this.$api.active.designTplSysList({
        page:this.page,
        active_mode:this.mode
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>